:root {
  --sitecolor: #42688E;
  --textcolor: #42688E;
  --titlecolor: #C4844F;
  --linkcolor: #42688E;
  --linkcolor-hov: #C4844F;
  --accentcolor: #42688E;
  --headerHeight: 204px;
  --gridGap: 50px;
}
@media (max-width: 1023px) {
  :root {
    --headerHeight: 125px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #7A7A78;
  color: #fff;
  position: relative;
  padding: 10px 20px 10px 40px;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    background-color: var(--sitecolor);
  }
}
@media (max-width: 767px) {
  .button {
    padding-left: 31px;
  }
}
.button:after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 10px;
  top: 15px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/button-arrow-right-white.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .button:after {
    width: 13px;
    height: 13px;
  }
}
.linkarrow {
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  padding-left: 25px;
  display: inline-block;
}
@media (max-width: 767px) {
  .linkarrow {
    padding-left: 21px;
  }
}
.linkarrow:after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 4px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/nav-arrow-right.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .linkarrow:after {
    width: 13px;
    height: 13px;
  }
}
.linkmail {
  --linkcolor: var(--sitecolor);
  --linkcolor-hov: #C4844F;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  padding-left: 25px;
  display: inline-block;
}
@media (max-width: 767px) {
  .linkmail {
    padding-left: 21px;
  }
}
.linkmail:after {
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 5px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-mail.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .linkmail:after {
    width: 13px;
    height: 13px;
  }
}
.linkextern {
  --linkcolor: #7A7A78;
  --linkcolor-hov: #C4844F;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
}
.linkextern:after {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 5px;
  left: 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-linkextern.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .linkextern:after {
    width: 13px;
    height: 13px;
  }
}
.download {
  --linkcolor: #7A7A78;
  --linkcolor-hov: #C4844F;
  line-height: 1.625;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
}
.download:after {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 5px;
  margin-bottom: -4px;
  left: 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-download.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
@media (max-width: 767px) {
  .download:after {
    width: 13px;
    height: 13px;
  }
}
.navigation div.navi {
  float: left;
}
.navigation div.navi > .item {
  float: left;
  position: relative;
}
.navigation div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1700px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.navigation__lang {
  align-self: flex-end;
}
.navigation div.sub1 {
  margin-top: -5px;
  display: flex;
}
.navigation div.sub1 > .item {
  display: block;
  display: flex;
}
.navigation div.sub1 > .item:not(.exit):after {
  content: '|';
  margin: 0 5px;
  color: var(--textcolor);
}
.navigation div.sub1 > .item > .menu {
  text-transform: uppercase;
}
.navigation div.sub2 {
  display: flex;
  gap: 28px;
}
.navigation div.sub2 > .item:hover > .menu:after,
.navigation div.sub2 > .item:focus > .menu:after {
  width: 100%;
}
.navigation div.sub2 > .item > .menu {
  padding-top: 18px;
  padding-bottom: 28px;
  font-size: 20px;
  line-height: 1.5;
  position: relative;
}
.navigation div.sub2 > .item > .menu:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 8px;
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
  background-color: var(--linkcolor-hov);
}
.navigation div.sub2 > .item > .menu.path:after {
  width: 100%;
}
.navigation div.sub3 {
  position: absolute;
  left: -50px;
  top: 100%;
  width: 360px;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: none;
  --linkcolor: #7A7A78;
  --linkcolor-hov: #42688E;
}
.navigation div.sub3 > .item {
  width: 100%;
  position: relative;
  margin: 10px 0;
}
.navigation div.sub3 > .item:hover > .menu,
.navigation div.sub3 > .item:focus > .menu {
  color: var(--linkcolor-hov);
}
.navigation div.sub3 > .item:hover > .menu:after,
.navigation div.sub3 > .item:focus > .menu:after {
  opacity: 1;
}
.navigation div.sub3 > .item > .menu {
  color: var(--linkcolor);
  hyphens: auto;
  padding: 0 50px 0 50px;
  font-size: 20px;
  line-height: 1.3;
  position: relative;
  font-weight: 600;
}
.navigation div.sub3 > .item > .menu:after {
  content: '';
  position: absolute;
  opacity: 0;
  top: 8px;
  left: 20px;
  width: 13px;
  height: 13px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/nav-arrow-right.svg);
  transition: all 0.4s cubic-bezier(0.76, 0, 0.24, 1);
}
.navigation div.sub3 > .item > .menu.path {
  color: var(--linkcolor-hov);
}
.navigation div.sub3 > .item > .menu.path:after {
  opacity: 1;
}
.navigation div.sub2 > .item:hover div.sub3 {
  display: block;
}
.area--one {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0 var(--gridGap);
}
.area--one .unit {
  grid-column: span 6;
}
.area--one .unitOne--1-1-indent {
  padding: 0 17.16171617%;
  box-sizing: border-box;
}
.area--one .unitOne--1-2 {
  grid-column: span 3;
}
.area--one .unitOne--1-3 {
  grid-column: span 2;
}
.area--one .unitTwo .unit__content {
  padding-left: 100px;
  padding-right: 40%;
  box-sizing: border-box;
}
.area--one .unitTwo .unit__body {
  padding: 0 30px 30px 30px;
}
.area--one .unitTwo .unit__foot {
  padding-left: 30px;
}
.area--one .unitTwo.unitTwo--variantOne {
  padding-right: 34.02854007%;
  box-sizing: border-box;
}
.area--one .unitTwo.unitTwo--variantTwo {
  padding-left: 34.02854007%;
  box-sizing: border-box;
}
.area--one .unitThree .unit__background {
  margin-left: -100px;
  width: calc(100% + 200px);
}
.area--one .unitThree .unit__content {
  padding-right: 50%;
  box-sizing: border-box;
}
.area--one .unitThree .unit__body {
  margin-top: -50px;
  padding-right: 20px;
  box-sizing: border-box;
}
.area--one .unitThree .unit__body:after {
  content: '';
  top: -40px;
  left: -100px;
  height: calc(100% + 40px);
  width: calc(100% + 100px);
  border-top-right-radius: 130px;
}
.area--two {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0 var(--gridGap);
}
.area--two .unit {
  grid-column: span 6;
}
.area--two .unitOne--1-3 {
  grid-column: span 2;
}
.area--three {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 var(--gridGap);
}
.area--three .unit {
  grid-column: span 2;
}
.area--three .unitOne--1-2 {
  grid-column: span 1;
}
.jobs {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 var(--gridGap);
}
.unit--form .unit__body {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 var(--gridGap);
}
.unit--form .part {
  grid-column: span 2;
}
.unit--form .part--slim {
  grid-column: span 1;
}
/*# sourceMappingURL=./screen-large.css.map */